/* * {
  box-sizing: border-box;
} */
.ifsa {
  width: 100%;
  object-fit: cover;
  transition: all 400ms;
}


.slider-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: larger;
}

.content h2 {
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
}

.content p {
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: large;
}

.arrow-container {
  color: white;
  position: absolute;
  top: 50%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.left-arrow {
  left: 20px;
  transition: 1s;
}

.left-arrow:hover {
  scale: 1.2;
  transition: 1s;
}

.right-arrow {
  right: 20px;
  transition: 1s;
}

.right-arrow:hover {
  scale: 1.2;
  transition: 1s;
}

button.btn-log.hover-white {
  background-color: black;
  color: white;
  border-radius: 4px;
  width: 150px;
  height: 50px;
  cursor: pointer;
  border: 0;
  transition: 1s;
}

button.hover-white:hover {
  scale: 1.1;
  background-color: white;
  color: black;
  transition: 1s;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.banne {
  margin-bottom: 180px;
}


.ifsa {
  animation: slide 1s ease-in-out;
  height: 700px;
}



@media (max-width: 767px) {
  .slider-container {
    height: 500px;
  }

  .ifsa {
    height: 500px;
  }

  .banne {
    margin-bottom: 100px;
  }

}