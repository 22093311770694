
.each-blog{
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-gap: 20px;
    width: 100%;
}

.each-blog a{
    box-shadow: 0 2px 2px 2px rgb(9 9 9 / 23%);
    margin: 20px;
    transition: 1s;
}

.each-blog a:hover{
    scale: 1.1;
    transition: 1s;
}


.each-blog img{
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.each-blog .text-wrapper h2{
    text-align: center;
}