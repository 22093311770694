/* * {
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (max-width: 560px) {
    body {
      background-color: lightgray;
    }
} */
.categories{
    display: flex;
    z-index: 2;
    position: relative;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.category{
    z-index: 2;
    display: flex;
    position: relative;
    transition: 1s;

    cursor: pointer;
}

.category:hover{
    scale: 1.1;
    display: flex;
    transition: 1s;
}

.category img{
    object-fit: cover;
    width: 300px;
    display: flex;
    height: 350px;
    border-radius: 15px;
}


.card-cont{
    position: absolute;
    left: 50%;
    top: 90%;
    justify-content: center;
    /* text-align: center; */
    /* height: 50%; */
    /* bottom: 0%; */
    transform: translate(-50%, -50%);
    /* color: black; */
    border-radius: 15px;
    width: 100%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
}

.card-cont h2{
    font-weight: 400;
}

.diamond{
    left: 70%;
    width: 200px;
    position: absolute;
    transform: "rotate(0deg)";
    z-index: 1; 
}

@media (max-width: 767px) {
    .category img{
      width: 100px;
      height: 150px;
    }
    
    .card-cont h2{
        font-size: small;
    }

    .diamond {
        left: 30%;
    }

    .categ{
        position: relative;
        z-index: 1;
        margin-bottom: 10px;
    }
  }