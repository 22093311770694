.cards-container{
    display: flex;
    justify-content: center;
}

.card {
    width: 200px;
    height: 200px;
    box-shadow: 0 2px 2px 2px rgb(9 9 9 / 23%);
    background-color: white;
    justify-content: space-between;
    margin: 50px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
}

.whyUS{
  position: relative;
}

.cardme{
  width: 50px;
}

.card-text{
  font-size: x-large;
}

@media (max-width: 767px) {
  .card {
    height: 100px;
    width: 100px;
    margin: 20px;
  }

  .cardme{
    width: 30px;
  }

  .card-text{
    font-size: small;
  }
}