.cardContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    row-gap: 30px;
    width: 100%;
    justify-items: center;
}

.cards {
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    box-shadow: 0px 2px 20px;
    height: 350px;
    width: 250px;
    position: relative;
    z-index: 2;
    transition: 1s;
    cursor: pointer;
}

.cards img {
    object-fit: cover;
    height: 250px;
    z-index: 2;
    width: 250px;
    border-radius: 10px;
}

.cards:hover{
    scale: 1.07;
    text-shadow:
    -0.8px 0 #000;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.cate-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    margin-top: auto;
}

.cardbtn {
    width: 90px;
    height: 30px;
    border: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 1s;
}

.price p {
    display: flex;
    align-items: flex-start;
}

.pnbtn {
    margin-bottom: 50px;
}

.arrows {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: x-large;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
    transition: 1s;
}

.arrows:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
}

.para{
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.para p{
    width: 400px;
}

.poo{
    display: flex;
    justify-content: center;
}

.poo p{
    width: 400px;
}

.column h3{
    text-align: center;
    font-family: 'Tinos', serif;
}

.wras{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.wras .card1{
    display: flex;
    flex-direction: column;
}

.wras .card2{
    display: flex;
    flex-direction: column;
}

.wras .card2 p{
    width: 400px;
}

@media (min-width: 375px) and (max-width: 767px)  {
    .cardContainer {
        display: grid;
        row-gap: 30px;
        grid-template-columns: repeat(3, minmax(50px, 1fr));
        width: 100%;
        justify-items: center;
    }

    .cards {
        height: 230px;
        width: 100px;
    }

    .cards p{
        font-size: small;
    }

    .cards img {
        height: 120px;
        width: 100px;
    }

    .diamond img {
        left: 25%;
    }

    .cardbtn {
        width: 50px;
        border-radius: 5px;
        font-size: 7px;
    }

    .wras .card2 p{
        width: 200px;
    }

    .poo p{
        width: 200px;
    }
    
}

@media (min-width: 320px) and (max-width: 375px) {
    .cardContainer{
        grid-template-columns: repeat(3, minmax(30px, 1fr));
    }

    .cards {
        height: 190px;
        width: 100px;
    }

    .cards img {
        height: 100px;
        width: 100px;
    }

    .cards p{
        font-size: x-small;
    }

    .wras .card2 p{
        width: 80px;
    }

    .poo p{
        width: 80px;
    }

    .cardbtn {
        width: 40px;
        border-radius: 5px;
        font-size: 7px;
        margin-bottom: 10px;
    }
}
