.product {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0;
}

.price {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.price p {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
}

.price del {
    font-weight: 600;
    font-size: 17px;
    color: rgb(60, 60, 60);
}

.product .big-image {
    width: 356px;
    overflow: hidden;
    object-fit: cover;
    margin: 25px;
    border-radius: 20px;
    transition: 1s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.big-image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.big-image:hover {
    scale: 0.9;
    transition: 1s;
}

.big-image video {
    width: 100%;
    height: 100%;
    /* max-height: 300px; */
    display: block;
    object-fit: cover;
}

.tas .measurebtn {
    border-radius: 10px;
    width: 100px;
    border: none;
    background-color: white;
    transition: 1s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.tas .measurebtn:hover {
    scale: 1.1;
    transition: 1s;
}

.thumb img {
    width: 90px;
    height: 150px;
    display: block;
    object-fit: cover;
    border: 1px solid #ddd;
    margin: 5px;
    opacity: 0.7;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
}

.thumb img:hover {
    scale: 1.1;
    transition: 1s;
}

.thumb video {
    width: 90px;
    height: 150px;
    display: block;
    object-fit: cover;
    border: 1px solid #ddd;
    margin-right: 5px;
    opacity: 0.7;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
}

.thumb video:hover {
    scale: 1.1;
    transition: 1s;
}

.prodcontent {
    width: 100%;
    /* text-align: center; */
    display: flex;
    align-items: flex-start;
    text-align: start;
    justify-content: center;
    flex-direction: column;
}

.product .prodcontent pre {
    font-size: 15px;
    white-space: break-spaces;
    font-family: 'Tinos', serif;
}

.stracture-make {
    display: flex;
    flex-direction: row;
}

.stracture-make .rotate {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
}

.thumb ul {
    list-style-type: none;
    width: 100%;
    height: 100%;
    display: flex;
    /* margin: 10px 0; */
    flex-direction: column;
    margin-left: 20px;
}

.thumb ul li {
    margin-bottom: 10px;
}

.pictur {
    display: flex;
    flex-direction: row-reverse;
}

.color-input button {
    transition: 0.7s;
    cursor: pointer;
    border: none;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.color-input button:hover {
    scale: 1.1;
    transition: 0.7s;
}

.diamond-info {
    width: 70%;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.diamond-info h3 {
    text-align: center;
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.heads {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.heads h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.diamond-info:hover {
    background: #B07612;
    padding-left: 10px;
    color: white;
}

.diamond-info:hover .accordion-arrow {
    color: white;
}

.product-title {
    display: block;
    text-align: center;
}

.popup {
    position: fixed;
    background-color: white;
    top: 30%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    /* Need a specific value to work */
    z-index: 200;
    border-radius: 10px;
    box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.1), 0 10px 10px 10px rgba(0, 0, 0, 0.1);
    transition: 1s;
    font-family: 'Tinos', serif;
}

.popup-transition {
    transition: opacity 0.3s ease-in-out;
}

.popup h2 {
    text-align: center;
}

.popup .measuresbtns {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    column-gap: 20px;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.popup .measuresbtns button {
    width: 100px;
    height: 25px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 1s;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
    outline: none;
}

.popup .measuresbtns button:focus{
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
    outline: none;
}

.popup .measuresbtns button:hover {
    scale: 1.1;
    transition: 1s;
}

.popup .close-btn{
    border: none;
    background-color: rgba(255, 0, 0, 0);
}

.modalCloseBTN{
    color: black;
    font-size: 30px;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
    position: fixed;
}

@media (max-width: 767px) {
    .product .big-image {
        width: 80%;
        margin: 2px;
    }

    .big-image img {
        justify-content: center;
        align-items: center;
    }



    .thumb ul {
        flex-direction: row;
        width: 100%;
    }

    .thumb img {
        width: 70px;
        height: 130px;
    }

    .pictur {
        flex-direction: column;
        align-items: center;
    }

    .stracture-make {
        flex-direction: column;
    }

    .thumb video {
        width: 70px;
        height: 130px;
    }

    .prodcontent {
        width: 100%;
    }

    .tas {
        margin: 0 auto;
    }

    .prodcontent pre {
        font-size: 5px;
        width: 300px;
        align-items: flex-start;
    }

    .thumb ul li {
        margin-bottom: 2px;
    }

    .diamond-info {
        width: 53%;
    }

    .diamond-info h3 {
        text-align: center;
        display: flex;
        margin: 5px;
        align-items: center;
        justify-content: center;
    }

    .product-title {
        display: inline-block;
        margin-right: 20px;
        text-align: left;
    }

    .popup {
        width: 350px;
        overflow-y: scroll;
        height: 200px;
        top: 20%;
    }

    .popup::-webkit-scrollbar {
        width: 10px;
    }

    .popup::-webkit-scrollbar-thumb{
        background: #B07612;
        border-radius: 10px;
    }

    .popup .measuresbtns {
        grid-template-columns: 90px 90px 90px;
        column-gap: 10px;
        row-gap: 10px;
    }

    .popup .measuresbtns button {
        width: 90px;
        height: 25px;
    }
}

.product-title-pc {
    display: block;
    margin-bottom: 10px;
    font-family: 'Tinos', serif;
}

.product-title-mobile {
    display: none;
    font-family: 'Tinos', serif;
}

@media only screen and (max-width: 600px) {
    .product-title-pc {
        display: none;
    }

    .product-title-mobile {
        display: block;
        margin-bottom: 10px;
    }
}