.ifsaa {
    width: 50%;
    margin: 0 auto; /* This centers the container horizontally */
}

.ifsaa img{
    width: 100%;
    height: auto;
    object-fit: cover;
    animation: fadeIn 2s ease-in-out forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
.cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    animation: fadeIn 2s ease-in-out forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.cont-c{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.cont-c p{
    text-align: center;
}

@media (max-width: 767px) {
    .cont{
        flex-direction: column;
    }

    .ifsaa{
        width: 100%;
    }
}