@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');
* {
   box-sizing: border-box; 
}

body{
  font-family: 'Muli',sans-serif;
  background-color: #f0f0f0;
}

.accordion{
  display: flex;
  flex-direction: column;
  align-items: center;

}

.accordion h1{
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}

.accordion h1::before{
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: #B07612;
  bottom: -25px;
  margin-left: -30px;
}

.accordion-item {
  background-color: transparent;
  border: 1px solid #9fa4a8;
  border-radius: 10px;
  margin: 5px 0;
  padding: 30px;
  position: relative;
  overflow: hidden;
  transition: 1s ease;
  }

  .accordion-item-active {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 
    6px rgba(0, 0, 0, 0.1);
    transition: 1s ease;
}
  
  .accordion-item {
    border-top: 1px solid #ccc;
    width: 80%;
  }

  .accordion-arrow-active svg {
    transform: rotate(180deg);
    transition: 1s;
  }

  .accordion-arrow{
    color: black;
  }

  .accordion-arrow svg{
    transition: 1s;
  }
  
  .accordion-item-header {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .accordion-item-content {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease-out;
  }
  
  .accordion-item-active .accordion-item-content {
    max-height: 500px; /* set a max-height that is greater than the expected height of the content */
    transition: 1s ease-in;
  }
  
  
  @media (max-width: 767px) {
    .accordion{
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fdfdfd;
      padding: 10vh 0 0;
    }
    .accordion-item{
      margin: 2px;
      padding: 20px;
      width: 280px;
    }

  }