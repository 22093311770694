.fa {
  color: #fff !important;
}

.foll {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #fff;
  padding: 0 1rem;
  height: 350px;
  width: 250px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 100%;
  /* use min-height instead of height */
}

.foll h2 {
  font-size: 20px;
  text-align: center;
}

.foll p {
  width: 240px;
}

.meida-clali {
  text-align: center;
}

.meida-clali a{
  text-decoration: none;
  color: white;
}

.meida-clali .ab {
  text-decoration: none;
  color: rgb(110, 109, 109);
  padding: 5px;
  background-color: #fff;
  transition: 1s;
  border-radius: 10px;
}

.meida-clali .ab:hover{
  transition: 1s;
  background-color: #9f9f9f;
  color: white;
}

.botto-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.first-sec {
  font-family: 'Tinos', serif;
}

.meida-clali .meidanosaf {
  background-color: white;
  color: black;
  border: none;
  height: 30px;
  width: 100px;
  font-family: 'Tinos', serif;
  transition: 1s;
  font-weight: 600;
  padding: 5px;
}

.meida-clali .meidanosaf:hover {
  scale: 1.1;
  background-color: rgb(182, 182, 182);
  color: black;
  transition: 1s;
}

.under {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.under .follo a i{
  transition: 1s;
}

.under .follo a i:hover{
  scale: 1.1;
  transition: 1s;
}

.mustIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mustIcons .icon{
  font-size: 30px; 
  margin-right: 5px;
  transition: 1s;
}

.mustIcons .icon:hover{
  scale: 1.1;
  transition: 1s;
}

.follo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width: 768px) {

  /* Adjustments for small screens */
  .foll {
    height: auto;
    min-height: 100%;
    border-left: none;
    border-top: 1px solid #fff;
    padding: 1rem;
  }

  .wr {
    flex-direction: column;
  }

  .foll h2 {
    font-size: 18px;
  }

  .foll p {
    width: 100%;
    max-width: 300px;
  }
}