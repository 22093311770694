.blog-header{
  height: 600px;
  margin-bottom: 30px;
}

.blog-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-container h2 {
  left: 50%;
  text-align: center;
}

.blog-titles{
  position: relative;
  display: flex;
  align-items: center;
}

