.floating-whatsapp-button {
  position: fixed;
  bottom: 15%;
  right: 5%;
  background-color: green;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

.floating-whatsapp-button:hover {
  background-color: #25d366;
}
